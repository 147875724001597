import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-full h-screen flex flex-col items-center justify-start bg-white">
      <h1 className="text-3xl font-bold p-4 text-center w-full">Politique de confidentialité de Life</h1>
      <p className="text-sm text-gray-500 text-center w-full">Dernière mise à jour : 28 /02/2025</p>
      
      <div className="w-full flex justify-center items-center px-4">
        <iframe 
          src="/docs/privacy-policy.pdf" 
          className="w-full h-screen border-none"
          style={{ width: "100vw", height: "calc(100vh - 80px)" }}
          title="Politique de Confidentialité"
        ></iframe>
      </div>
    </div>
  );
};

export default PrivacyPolicy;