import React from 'react';
import '../Style/Footer.css'; 
import logo from '../Images/lifelogo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation('common');

  return (
    <footer className="footer">
      <div className="footer-logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="footer-content">
        <div className="footer-section">
          <h4>{t('footer.quickLinksTitle')}</h4>
          <ul>
            <li><Link to="/Solution/Gérez-votre-empreinte-carbone">{t('footer.sustainabilityManagement')}</Link></li>
            <li><Link to="/Solution/Présentation-solution">{t('footer.universalIntelligence')}</Link></li>
            <li><Link to="https://onedrive.live.com/?id=7D8D5CA51FD1FAAA%217748&cid=7D8D5CA51FD1FAAA">{t('footer.documentation')}</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>{t('footer.aboutLifeTitle')}</h4>
          <ul>
            <li><Link to="/à-propos">{t('footer.companyInfo')}</Link></li>
            <li><Link to="/rejoignez-nous/devenez-actionnaire">{t('footer.investors')}</Link></li>
            <li><Link to="/rejoignez-nous/devenez-collaborateur">{t('footer.jobs')}</Link></li>
            <li><Link to="/rejoignez-nous/devenez-partenaire">{t('footer.partners')}</Link></li>
            <li><Link to="/Contactez-nous">{t('footer.contactUs')}</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>{t('footer.siteInfoTitle')}</h4>
          <ul>
            <li><Link to="/Nos-termes-utilisation">{t('footer.termsOfUse')}</Link></li>
            <li><Link to="/Politique-confidentialite">{t('footer.PrivacyPolicy')}</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>{t('footer.allRightsReserved')} &copy;{new Date().getFullYear()}</p>
        <div className="social-media-icons">
          <Link to="https://www.instagram.com/lifers_fr/?igsh=NmMzcnpkaWF1NjY4" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </Link>
          <Link to="https://www.linkedin.com/in/life-software-a865792b5/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </Link>
          <Link to="https://www.youtube.com/@Life-db1jo" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
          </Link>
          <Link to="https://twitter.com/BeLifeuse" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faXTwitter} />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
