import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Accueil from './Containers/Accueil';
import Finance from './Containers/Produit/Finance';
import Time from './Containers/Produit/Time';
import Social from './Containers/Produit/Social';
import Admin from './Containers/Produit/Admin';
import Pro from './Containers/Produit/Pro';
import Perso from './Containers/Produit/Perso';
import Eco from './Containers/Produit/eco';
import General from './Containers/Produit/General';
import Domicile from './Containers/Produit/Domicile';
import Produit from './Containers/Produit/Produit';
import LoginPopup from './Components/LoginPopup';
import Part from './Containers/Produit/Part'
import Roadmap from './Containers/Roadmap'

import Testeur from './Containers/About/Testeur';
import Job from './Containers/About/Job';
import Partenaire from './Containers/About/Partenaire';
import Actionnaire from './Containers/About/Actionnaire';
import Aboutus from './Containers/About/Aboutus';
import ContactUs from './Containers/About/Contactus';

import './Style/App.css';
import LifeHistory from './Containers/About/LifeHistory';
import Offre from './Containers/Produit/Offre';
import TermsOfUse from './Components/TermsOfUse';
import PrivacyPolicy from './Containers/Legal/PrivatePolicy';

import Jeune from './Containers/Solution/jeune';
import Parent from './Containers/Solution/parent';
import Techno from './Containers/Solution/techno';

function App() {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  return (
    <Suspense fallback={<div>Loading translations...</div>}>
      <Router>
        <Header onUserIconClick={() => setShowLoginPopup(true)} /> 
        {showLoginPopup && (
          <LoginPopup onClose={() => setShowLoginPopup(false)} />
        )}
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/Solution" element={<Produit />} />
          <Route path="/Solution/Gérez-vos-finances" element={<Finance />} />
          <Route path="/Solution/Gérez-votre-temps" element={<Time />} />
          <Route path="/Solution/Gérez-vos-relations" element={<Social />} />
          <Route path="/Solution/Gérez-votre-vie-administrive" element={<Admin />} />
          <Route path="/Solution/Gérez-votre-carrière-pro" element={<Pro />} />
          <Route path="/Solution/Gérez-votre-santé-sport-alimentation-spiritualité" element={<Perso />} />
          <Route path="/Solution/Gérez-votre-empreinte-carbone" element={<Eco />} />
          <Route path="/Solution/Présentation-solution" element={<General />} />
          <Route path="/Solution/Gérez-votre-immobilier-et-vos-véhicules" element={<Domicile />} />
          <Route path="/rejoignez-nous/Devenez-Testeur" element={<Testeur />} />
          <Route path="/rejoignez-nous/devenez-collaborateur" element={<Job />} />
          <Route path="/rejoignez-nous/devenez-partenaire" element={<Partenaire />} />
          <Route path="/rejoignez-nous/devenez-actionnaire" element={<Actionnaire />} />
          <Route path="/à-propos" element={<Aboutus />} />
          <Route path="/à-propos/histoire-de-life" element={<LifeHistory />} />
          <Route path="/Notre-offre" element={<Offre />} />
          <Route path="/rejoignez-nous" element={<Part />} />
          <Route path="/Notre-feuille-de-route" element={<Roadmap />} />
          <Route path="/Contactez-nous" element={<ContactUs />} />
          <Route path="/Nos-termes-utilisation" element={<TermsOfUse />} />
          <Route path="/Solution/Nos-solutions-pour-les-jeunes" element={<Jeune />} />
          <Route path="/Solution/Nos-solutions-pour-les-familles" element={<Parent />} />
          <Route path="/Solution/Nos-solutions-pour-les-technophiles" element={<Techno />} />
          <Route path="/Politique-confidentialite" element={<PrivacyPolicy />} />
        </Routes> 
        <Footer />    
      </Router>
    </Suspense>
  );
}

export default App;
